import { collection, orderBy, query, Query } from "firebase/firestore";
// @ts-ignore
import { collectionData } from "rxfire/firestore";
import { map } from "rxjs";
import { Checklist, Checklists } from "types/Checklist";

export const useUserChecklists = () => {
  // Eventually we'll stream checklist form the user's library of checklists
  const streamUserChecklists = () => {
    const db = useFirestore();

    const collectionRef = collection(db, `/checklists`);
    let collectionQuery = query(collectionRef) as Query<Checklist>;

    const appUserData = useAppUserData();
    const { isOnlineInstructor } = storeToRefs(appUserData);
    collectionQuery = query(collectionQuery, orderBy("title"));

    const levelsStore = useLevels();
    const { allLevels } = levelsStore;

    const categoriesStore = useIEWProductCategories();

    return collectionData<Checklist>(collectionQuery, { idField: "id" }).pipe(
      map((checklists: Checklists) => {
        checklists = checklists.map((checklist) => {
          return {
            ...checklist,
            path: `/checklists`,
          };
        });

        if (appUserData.isUserAdmin) {
          return useSortChecklists(checklists);
        }

        const filteredChecklists = [] as Checklists;

        for (const checklist of checklists) {
          const matchingLevel = allLevels.find(
            (level) => level.id == checklist.levelId
          );

          const categories =
            isOnlineInstructor.value != true
              ? categoriesStore.publicIEWProductCategories
              : categoriesStore.allIEWProductCategories;

          const matchingCategory = categories.find(
            (category) =>
              category.id == matchingLevel?.iewGradebookProductCategoryId
          );

          const hasMatchingLevel =
            appUserData.availableLevelIds.length == 0 ||
            appUserData.availableLevelIds.includes(checklist.levelId);

          const hasMatchinLesson =
            appUserData.availableLessonIds.length == 0 ||
            appUserData.availableLessonIds.includes(checklist.lessonId);

          if (matchingCategory && hasMatchingLevel && hasMatchinLesson) {
            filteredChecklists.push(checklist);
          }
        }

        return useSortChecklists(filteredChecklists);
      })
    );
  };

  return { streamUserChecklists };
};
